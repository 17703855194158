import { NavigationItemModel } from 'app/core/models/navigation-item.model';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Signal, computed, inject, signal } from '@angular/core';
import { SvgComponent } from 'app/shared/svg-component/svg-component.component';
import { Store } from '@ngrx/store';
import { selectAuthentication } from 'app/store/reducers';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { AuthenticationState } from 'app/store/states/authentication.state';
import { footerItems } from 'app/core/data/navigation-data';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';
import { environment } from 'environments/environment';
import { DataLogicService } from 'app/services/data-logic/data-logic.service';
import { HttpResponse } from '@angular/common/http';



@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [SvgComponent,NgOptimizedImage,TranslocoModule,RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  private _store = inject(Store);
  private _dataService = inject(DataLogicService<{}>)
  private _translocoService = inject(TranslocoService);
  private _unsubscribeAll : Subject<void> = new Subject<void>();
  currentYear = new Date().getFullYear();
  footerNavItems : Signal<NavigationItemModel[]> = signal([]);
  isLogged = signal(false);
  spartanjob = 'SpartanJob.'
  socialIcons : {svgName:string,svgLink?:string}[]= [
    {
      svgName:'facebook.png',
      svgLink:'https://www.facebook.com/profile.php?id=61562533883449',
    },
    {
      svgName:'tiktok.png',
      svgLink:'https://www.tiktok.com/@spartanjob',
    },
    {
      svgName:'instagram.png',
      svgLink:'https://www.instagram.com/spartanjob.ch/',
    },
    {
      svgName:'linkedin.png',
      svgLink:'https://www.linkedin.com/company/spartanjob/?viewAsMember=true',
    }
  ];
  cguUrl = `${environment.ENDPOINTS.ALL_POLICIES_URL}CGU`;
  ppdUrl = `${environment.ENDPOINTS.ALL_POLICIES_URL}PPD`;

  ngOnInit(): void {
    /** auth state listener */
    this.authStateListener();
  }

      /** auth state listener */
      authStateListener(): void {
        this._store.select(selectAuthentication).pipe(
          takeUntil(this._unsubscribeAll),
          tap((authState: AuthenticationState) => {
            /// set is logged and user info
            this.isLogged.set(authState.isLoggedIn);
            /** set footer elements  */
            this.setFooterElements();
          })
        ).subscribe()
      }

    /** set footer elements */
      setFooterElements():void {
      const allFooterElements: NavigationItemModel[] = footerItems;
      this.footerNavItems = computed (()=>{ return this.isLogged() ? allFooterElements : allFooterElements
        .filter((footerElement: NavigationItemModel) => footerElement.privilege === 'public')
        .map((footerElement: NavigationItemModel) => footerElement);})
    }

    get({ event, type }: { event: any, type: string }) {
      event.preventDefault(); // Empêche la navigation par défaut
    
      const currentLang = this._translocoService.getActiveLang();
      const headers = { lang: currentLang };
    
      const url: { [key: string]: string } = {
        'CGU': this.cguUrl,
        'PPD': this.ppdUrl,
      };
    
      const paramsToConcat = { headers, observe: 'response', responseType: 'blob' };
    
      this._dataService.getData({ url: url[type], paramsToConcat }).pipe(
        tap((response: HttpResponse<any>) => {
          // Crée un lien temporaire pour télécharger ou ouvrir le fichier
          const blobUrl = window.URL.createObjectURL(response?.body);
    
          // Ouvre un nouvel onglet ou fenêtre immédiatement après la réponse
          const newWindow = window.open();
          if (newWindow) {
            newWindow.location.href = blobUrl; // Assurez-vous que la fenêtre est bien ouverte
          } else {
            console.error('La fenêtre n\'a pas pu être ouverte.');
          }
        }),
        catchError((err) => {
          console.error('Erreur lors du téléchargement des CGU:', err);
          return of(err);
        })
      ).subscribe();
    }
 
}
